<template>
	<v-container class="pa-0" @mouseover.stop="showGroupBtns = true" @mouseout="showGroupBtns = false">
		<!-- Selects reglas -->
		<div v-if="filterGroupModel && filterGroupModel.length > 0">
			<v-row no-gutters v-for="(rule, index) in filterGroupModel" :key="index" class="mb-2">
				<v-col cols="12" class="pa-0 d-flex align-center">
					<status-form-filter-input
						:ref="`statusInput${index}`"
						:ruleIndexProp="index"
						:groupName="groupName"
						:rowsProp="rowsProp"
						:filterRuleProp="rule"
						:disabled="disabled"
						@change="onFilterRuleChanges(index)"
					></status-form-filter-input>
					<v-btn
						icon
						@click="removeRule(index)"
						:disabled="filterGroupModel.length == 1 || disabled"
						class="d-flex justify-center align-center ml-1"
					>
						<v-icon class="delete-btn" small :ripple="false">fa fa-trash-alt</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</div>

		<!-- Buttons acciones -->
		<v-col cols="12" class="elevation-0 pa-0">
			<v-btn class="btnGroup btnAddRule elevation-0" @click="addRule" :disabled="filterGroupModel.length >= maxFilters || disabled">
				<v-icon small>far fa-plus</v-icon>
				<span>{{ $t('pmstatus.addStatus') }}</span>
			</v-btn>
		</v-col>
	</v-container>
</template>

<script>
import StatusFormFilterInput from './StatusFormFilterInput';

export default {
	name: 'StatusFormFilterGroup',
	components: {
		StatusFormFilterInput
	},
	props: {
		isRootGroup: {
			type: Boolean,
			default: false
		},
		groupIndexProp: {
			type: Number,
			default: 0
		},
		filterGroupProp: {
			required: true
		},
		rowsProp: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
		showSubgroupBtn: {
			type: Boolean
		},
		maxFilters: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			filterGroupModel: this.getDefaultFilterGroupModel(),
			showDeleteRule: null,
			showGroupBtns: null,
			groupOperators: [
				{
					text: 'and',
					value: 'and'
				},
				{
					text: 'or',
					value: 'or'
				}
			]
		};
	},
	computed: {
		groupName() {
			return this.isRootGroup ? this.groupIndexProp.toString() : this.$parent.groupName + '-' + this.groupIndexProp;
		},
		parentGroupName() {
			return this.isRootGroup ? null : this.$parent.groupName;
		}
	},
	watch: {
		filterGroupProp() {
			if (this.filterGroupProp) {
				this.filterGroupModel = this.filterGroupProp;
			}
		},
		filterGroupModel: {
			deep: true,
			handler() {
				console.info('filterGroupModel', this.filterGroupModel);
				this.$emit('change', this.filterGroupModel);
			}
		}
	},
	created() {
		this.translateGroupOperators();
		if (this.filterGroupProp) {
			this.filterGroupModel = this.filterGroupProp;
		}
	},
	mounted() {
		this.$puiEvents.$on('reset-filtering', this.reset);
		this.$puiEvents.$on(`groupRemoved-${this.groupName}`, this.onGroupRemoved);
		this.$puiEvents.$on(`subGroupAdded-${this.groupName}`, this.onSubGroupAdded);

		if (this.filterGroupModel.length === 0) {
			this.addRule();
		}
	},
	destroyed() {
		this.$puiEvents.$off('reset-filtering');
		this.$puiEvents.$off(`groupRemoved-${this.groupName}`);
		this.$puiEvents.$off(`subGroupAdded-${this.groupName}`);
	},
	methods: {
		getUniqueIndex() {
			let numberSTR = Math.random().toString().replace('.', Math.random().toString().replace('.', '').substring(0, 10)).substring(0, 20);
			let numberInt = parseInt(numberSTR).toFixed();
			let integer = parseInt(numberInt);
			return integer;
		},
		translateGroupOperators() {
			for (let i = 0, length = this.groupOperators.length; i < length; i++) {
				this.groupOperators[i].text = this.$t(this.groupOperators[i].text);
			}
		},
		reset() {
			if (this.isRootGroup) {
				this.filterGroupModel = this.getDefaultFilterGroupModel();
				this.addRule();
			}
		},
		onGroupRemoved(index) {
			//alert('soy el grupo ' + this.groupName + ` recibo el evento en el grupo ${this.groupName} con el indice ${index}`);
			this.filterGroupModel.groups.splice(index, 1);
		},
		onFilterGroupChanges(index) {
			this.filterGroupModel.groups[index] = this.$refs[`group${index}`][0].filterGroupModel;
		},
		onFilterRuleChanges(index) {
			this.filterGroupModel[index] = this.$refs[`statusInput${index}`][0].filterRuleModel;
		},
		addRule() {
			if (this.filterGroupModel.length <= this.maxFilters - 1) {
				this.filterGroupModel.push(StatusFormFilterInput.methods.getDefaultFilterRuleModel());
			}
		},
		removeRule(index) {
			this.filterGroupModel.splice(index, 1);
		},
		getDefaultFilterGroupModel() {
			return [
				{
					name: 'Test1',
					status: {
						max: {
							op: 'lte',
							data: 50
						}
					},
					color: '#000000'
				}
			];
		}
	}
};
</script>

<style lang="postcss" scoped>
.btnGroup {
	height: 28px !important;
	color: var(--N-500);
	& span {
		padding-left: 6px;
	}
}
.delete-btn {
	min-height: 100% !important;
}
.puiFilterGroup-custom {
	&__groupOperator {
		height: 50px;
	}
	&__bottombar {
		background-color: transparent !important;
	}
	& .puiFilterGroup-custom {
		background-color: var(--N-10);
		& .v-btn {
			background-color: var(--N-10);
		}
		& .puiFilterGroup-custom {
			background-color: var(--N-0);
			& .v-btn {
				background-color: var(--N-0);
			}
			& .puiFilterGroup-custom {
				background-color: var(--N-10);
				& .v-btn {
					background-color: var(--N-10);
				}
				& .puiFilterGroup-custom {
					background-color: var(--N-0);
					& .v-btn {
						background-color: var(--N-0);
					}
					& .puiFilterGroup-custom {
						background-color: var(--N-10);
						& .v-btn {
							background-color: var(--N-10);
						}
						& .puiFilterGroup-custom {
							background-color: var(--N-0);
							& .v-btn {
								background-color: var(--N-0);
							}
						}
					}
				}
			}
		}
	}
}
</style>
